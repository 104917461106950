import $ from 'jquery';
import Carousel from './libs/bootstrap/carousel';
import Masonry from './libs/masonry';

class Application
{
	constructor()
	{
		this.setupCarousels();
		this.setupBLRVideo();
		this.setupMasonryGrid();
		
		if( window.location.pathname != '/' )
			this.setLinkBackToVideo();

		// Enable debug controls if we're running on localhost
		if( window.location.href.indexOf( 'localhost' ) > -1 )
			this.enableDebugControls();
	}

	setupCarousels()
	{
		Carousel.Default.interval = 8000;

		$( '#quote-carousel-container' ).on( 'slide.bs.carousel', function () {
		  $( '.quote-content' ).fadeOut( 'fast' );
		} );

		$( '#quote-carousel-container' ).on( 'slid.bs.carousel', function () {
		  $( '.quote-content' ).fadeIn( 'fast' );
		} );
	}

	setupBLRVideo()
	{
		var self = this;

		if( $('#video_1').length <= 0 ) return;

		var cookieVal = self.getCookie( 'blr_fhts_cookie' );
		var player;

		videojs( 'video_1' ).ready( function() 
		{
            player = this;
            var watched = ( cookieVal !== undefined ) ? JSON.parse( cookieVal ).watched : false;

            player.on( 'ended', function() {
        		console.log('video ended');
                self.onVideoEnded( $( '#video_1' ), player );
            } );

            if( !watched )
            {
            	self.showBLRVideo( $( '#video_1' ) );
            	player.play();
            }
        } );

		$( '.close-btn' ).on( 'click', function(){
        	self.hideBLRVideo( $( '#video_1' ) );
        	player.pause();
        	self.onVideoEnded( $( '#video_1' ), player );
        } );

        $( '.watch-btn' ).on( 'click', function(){
        	self.showBLRVideo( $( '#video_1' ) );
        	player.play();
        } );

	}

	setupMasonryGrid()
	{
		var self = this;

		var elem = document.querySelector('.grid');

		if( elem === null || elem.length <= 0 ) return;

		var msnry = new Masonry( elem, {
		  // options
		  itemSelector: '.grid-col',
		  columnWidth: '.grid-sizer',
		  horizontalOrder: true,
		  percentPosition: true
		});

		msnry.layout();

		var reload = setTimeout( function(){
			msnry.layout();
		}, 50 );
	}

	setLinkBackToVideo()
	{
		var self = this;
		$( '.watch-btn' ).on( 'click', function( e ){
			e.preventDefault();

        	self.setCookie( 'blr_fhts_cookie', '', 0 );
        	window.location.href = '/';
        } );
	}

	onVideoEnded( el, player )
	{
		player.currentTime( 0 );
		this.hideBLRVideo( el );
		this.setCookie( 'blr_fhts_cookie', JSON.stringify({ watched : true }), 30 );
	}

	showBLRVideo( el )
	{
		el.parent().parent().removeClass('hidden');
		$( '.book-cover' ).addClass('hidden');
		$( '.watch-btn' ).addClass( 'invisible' );
	}

	hideBLRVideo( el )
	{
		el.parent().parent().addClass('hidden');
		$( '.book-cover' ).removeClass('hidden');
		videojs( 'video_1' ).pause();
		$( '.watch-btn' ).removeClass( 'invisible' );
	}

	enableDebugControls()
	{
		var self = this;
		$( window ).keypress( function( e ) {
			if( e.keyCode === 46 ) // End video
			{
				console.log( "Debug Command: ENDING VIDEO" );
				videojs( 'video_1' ).ready( function() 
				{
		            var player = this;
		            player.pause();
		            self.onVideoEnded( $( '#video_1' ), player );
		        } );
			}
			else if( e.keyCode === 44 ) // Reset Cookie
			{
				console.log( "Debug Command: RESET COOKIE" );
				self.setCookie( 'blr_fhts_cookie', '', 0 );
			}
		} );
	}

	setCookie( name, val, duration )
	{
	    var d = new Date();
	    d.setTime( d.getTime() + ( duration * 24 * 60 * 60 * 1000 ) );
	    var expires = 'expires=' + d.toUTCString();
	    document.cookie = name + '=' + val + ';' + expires + ';path=/';
	}

	getCookie( name )
	{
	    name = name + '=';
	    var decodedCookie = decodeURIComponent( document.cookie );
	    var ca = decodedCookie.split( ';' );
	    for( var i = 0; i < ca.length; i++ )
	    {
	        var c = ca[ i ];
	        while ( c.charAt(0) == ' ' )
	        {
	            c = c.substring(1);
	        }
	        if ( c.indexOf( name ) == 0 ) {
	            return c.substring( name.length, c.length );
	        }
	    }

	    return undefined;
	}
}
let application = new Application();
